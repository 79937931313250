<template>
    <h1 class="df_title">Lakásbiztosítási szerződés - {{ client.name }} részére</h1>
    <h2>{{ client.modus_name }}</h2>
    <div class="df_main" v-if="isValid && !isSend">
        <h3>Tisztelt {{ client.name }}!</h3>
        <p>Köszönjük, hogy lakásbiztosítási szerződésének megkötéséhez a Grantis Hungary Zrt. közreműködését választotta!</p>

        <p>Ahhoz, hogy a <b>{{ client.insurer_name }}</b> Biztosító Zrt. Ön által már kiválasztott <b>{{ client.modus_name }}</b> termékének biztosítási ajánlatát az Ön képviseletében benyújthassuk a biztosítóba, az alábbi nyilatkozatok megtételére van szükség.</p>

        <p>Kérjük, hogy a négyzetek bejelölése előtt szíveskedjen a megnyíló dokumentumokat figyelmesen elolvasni. A négyzetek bejelölésére a kapcsolódó dokumentum megnyitása után kerülhet sor.</p>

        <p>A lenti nyilatkozatok megtételét követően a Grantis Hungary Zrt. visszaigazoló levelet fog küldeni az Ön által megadott e-mail címre. A visszaigazoló levél mellékleteként a megbízásra és a biztosításra vonatkozó valamennyi fontos dokumentumot is megküldjük Önnek, melyeket szíveskedjen áttekinteni. 
            A visszaigazoló levél azt is tanúsítja, hogy a biztosítási ajánlatot benyújtottuk a kiválasztott biztosító részére, de nem igazolja a biztosítás létrejöttét (azt a biztosító fogja megtenni).</p>

        <p>Bármilyen további kérdése, észrevétele esetén állunk szíves rendelkezésére.</p>

        <p>Üdvözlettel, <br>
        Grantis Hungary Zrt.</p>
    </div>
    <div class="df_main" v-else-if="isSend">
        <p>Köszönjük, hogy lakásbiztosításának megkötéséhez a Grantis Hungary Zrt segítségét kérte. <br>
        Kollégáink hamarosan megküldik önnek a végleges dokumentációt.</p>

        <p>Üdvözlettel,<br>
        A Grantis csapata</p>
    </div>
    <div class="df_main" v-else>
        <p>Az Ön által megnyitott hivatkozás egy már korábban lezárult folyamathoz kapcsolódik, a dokumentumok véglegesítése megtörtént. <br>
        Amennyiben a megkötött szerződéssel, vagy az ügyintézéssel kapcsolatban kérdése merült fel, kérjük hívja bizalommal Ügyfélszolgálatunkat.</p>

        <p>Üdvözlettel,<br>
        A Grantis csapata</p>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({ isValid: 'getIsValid', client: 'getClientData', isSend: 'getIsSend' })
    }
}
</script>

<style lang="scss" scoped>
h3 {
    margin-top: 0;
    margin-bottom: 0;
}

h2 {
    text-align: center;
    font-size: 22px;
    margin-top: 0;
    font-weight: 500;
}

p {
    line-height: 20px;
}
</style>