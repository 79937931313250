<template>
  <Header/>
  <router-view></router-view>
</template>

<script>
import Header from "@/components/Header";

export default {
  components: {
    Header
  },
}
</script>

<style lang="scss">
  @import "@/assets/scss/main";
</style>