import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import title from '@/mixins/title';
import directives from '@/directives'
import { maska } from 'maska';
import { registerGlobalComponents } from './components/global';

const app = createApp(App);

app.mixin(title);
app.use(store);
app.use(router);
app.directive('mask', maska);

registerGlobalComponents(app);

directives(app);

app.mount('#app');
