<template>
    <div class="df_header--wrapper">
        <img data-src="@/assets/images/header.png" alt="Jelentkezés tanácsadásra fejléc" class="lozad df_header--img" src="@/assets/images/header.png" data-loaded="true">
        <div class="df_header--container">
            <a href="https://grantis.hu/">
                <img data-src="@/assets/images/grantis_logo.svg" alt="GRANTIS Hungary Zrt." class="lozad df_header--logo" src="@/assets/images/grantis_logo.svg" data-loaded="true">
            </a>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
    .df_header {
        &--wrapper {
            height: 83px;
            margin-bottom: 33px;
        }

        &--container {
            position: relative;
            max-width: 1124px;
            width: 100%;
            height: 100%;
            padding: 0 15px;
            margin: 0 auto;
            display: flex;
            align-items: center;
        }

        &--img {
            position: absolute;
            background: color(custom, yellow1);
            height: 83px;
            width: 100%;
            object-fit: cover;
            object-position: bottom;
        }

        &--logo {
            position: relative;
        }
    }
</style>