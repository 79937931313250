import { defineRule, configure } from 'vee-validate';
import { required, email, alpha_spaces, digits } from '@vee-validate/rules';
import { localize, setLocale } from '@vee-validate/i18n';
import hu from '@vee-validate/i18n/dist/locale/hu.json';
import { DateTime } from 'luxon';

/** Setup necessary default rules */
defineRule('required', required);
defineRule('email', email);
defineRule('alpha_spaces', alpha_spaces);
defineRule('digits', digits);

/* Create phone validator based on regex pattern */
defineRule('phone', value => {
	return /^(?:.{11,13})[- +()0-9]+$/.test(value);
});

defineRule('date', value => {
	return DateTime.fromISO(value).isValid;
});

configure({
	// Generates an Hungarian message locale generator
	generateMessage: localize({ hu }),
});

setLocale('hu');
