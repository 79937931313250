<template>
	<Field :name="input.code" v-slot="validator" v-model="computedValue" :rules="rules">
		<div class="input-label__wrapper">
			<span class="input-label">{{ input.name }}</span>
			<div class="field-hint" v-if="input.hint">
				<span class="field-hint__icon" v-tooltip:left="input.hint">?</span>
			</div>
		</div>
		<div class="field" :class="validationClasses(validator)">
			<input
				v-mask="mask"
				v-bind="validator.field"
				:id="generatedId"
				placeholder=" "
				:type="input.type === 'phone' ? 'tel' : input.type"
				:inputmode="input.type"
			/>
			<label :for="generatedId">{{ input.name }}</label>
			<ErrorMessage :name="input.name" class="error_message" />
		</div>
	</Field>
</template>

<script>
import input from '@/mixins/input';

export default {
	mixins: [input],
	computed: {
		rules() {
			let rules = ['required'];

			if (this.input.type === 'email') {
				rules.push('email');
			}

			if (this.input.type === 'date') {
				rules.push('date');
			}

			if (this.input.type === 'phone') {
				rules.push('phone');
			}

			return rules.join('|');
		},
		mask() {
			if (this.input.type === 'phone') {
				return '## ## ### ####';
			}

			if (this.input.code === 'zipCode') {
				return '####';
			}

			if (this.input.code === 'taj') {
				return '### ### ###';
			}

			return '';
		},
	},
};
</script>
