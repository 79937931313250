export default {
    mounted() {
        document.addEventListener('click', () => {
            this.show = false;
        });
    },
    data() {
        return {
            show: false,
        }
    },
    computed: {
        label() {
            return this.computedValue === null ? 'Kérlek válassz...' : this.getOptionName();
        },
    },
    methods: {
        onClick(e) {
            e.stopPropagation();
            this.setAllInactive();
            this.show = !this.show;
        },
        setAllInactive() {
            const selects = document.querySelectorAll('.select-chooser');

            selects.forEach(select => {
                if(this.$refs.list !== select && select.style.display === '') {
                    select.parentNode.click();
                }
            });
        },
        isSelected(value) {
            if(this.computedValue !== null) {
                return this.computedValue.includes(value); 
            }

            return false;
        }
    }
}