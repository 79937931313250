<template>
    <component 
        :is="component(input.type)"
        :input="input"
    />
    <button
        class="copy"
        v-if="input.copy_id !== null" 
        v-tooltip="`Másolás innen: ${input.copy.name}`"
        @click="copyDataValue(input.copy.code, input.code)"
    >
    <img src="@/assets/images/copy_icon.svg" />
    </button>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    props: {
        input: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            components: {
                text: 'gts-input',
                select: 'gts-select',
                multiselect: 'gts-multiselect',
                email: 'gts-input',
                date: 'gts-input',
                phone: 'gts-input',
            }
        }
    },
    computed: {
        component() {
            return (type) => this.components[type];
        }
    },
    methods: {
        ...mapActions(['copyData']),
        async copyDataValue(from, to) {
            await this.copyData({ from, to });
        }
    }
}
</script>

<style lang="scss" scoped>
.copy {
    position: absolute;
    top: 0;
    right: 15px;
    border-radius: 100%;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:disabled):active, &:not(:disabled):focus {
        box-shadow: unset;
    }

    img {
        width: 1.2rem;
        height: 1.2rem;
    }
}
</style>