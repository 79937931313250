<template>
    <gts-spinner fixed :show="loading"/>
    <Content v-if="!loading"/>
    <Form v-if="isValid && !isSend"/>
</template>

<script>
import { mapGetters } from "vuex";
import init from "@/mixins/init";
import Content from "@/components/HomeInsurance/Content";
import Form from "@/components/HomeInsurance/Form";

export default {
    title: 'Lakásbiztosítási dokumentumok',
    mixins: [init],
    components: {
        Content,
        Form
    },
    computed: {
        ...mapGetters({ isValid: 'getIsValid', isSend: 'getIsSend' })
    }
}
</script>

<style lang="scss" scoped>

</style>