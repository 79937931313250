<template>
    <Form as="div" ref="form" v-slot="{ meta }">
        <div class="container-fluid">
            <div class="row" v-for="(inputs, group) in groupedInputs" :key="group">
                <div class="col-sm-12">
                    <div class="df_main__header">
                        <h2>{{ group }}</h2>
                    </div>
                    <div class="df_main row">
                        <div class="col-sm-4 my-1" v-for="input in inputs" :key="input.id">
                            <gts-input-field :input="input"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <gts-button @click="handleSend" :disabled="!meta.valid || loading" :loading="loading">Adatok beküldése</gts-button>
    </Form>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { Form } from 'vee-validate';
import "@/validation";

export default {
    components: {
        Form
    },
    computed: {
        ...mapGetters({ client: 'getClientData', groupedInputs: 'getGroupedInputs', hash: 'getHash' })
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        async handleSend() {
            this.loading = true;
            const result = await this.$refs.form.validate();

            if(result.valid) {
                const response = await axios.post(`${process.env.VUE_APP_DASHBOARD_API_URL}/data-requester/send`, {
                    data: this.client,
                    hash: this.hash
                });
                
                this.$store.commit('setIsSend', response.data.success);
            }

            this.loading = false;
        }
    }
}
</script>

<style lang="scss" scoped>
    .df_main {
        margin-top: 15px;
        padding-top: 15px;

        h2 {
            margin-top: 0;
        }
    }

    button {
        margin-top: 30px;
        margin-bottom: 50px;
    }
</style>