<template>
    <div class="df_main">
        <Form as="div" ref="form" v-slot="{ meta }">
            <gts-checkbox
                :value="checkboxes.received.accepted"
                name="received"
                :text="checkboxes.received.text"
                :disabled="false"
                :download="checkboxes.received.download"
                @onAccepted="onAccepted"
                :required="checkboxes.received.required"
            />
            <gts-checkbox
                :value="checkboxes.email.accepted"
                name="email"
                :text="checkboxes.email.text"
                :disabled="false"
                :download="checkboxes.email.download"
                @onAccepted="onAccepted"
                :required="checkboxes.email.required"
            />
            <gts-checkbox 
                v-for="(document, name) in documents" :key="name" 
                :value="checkboxes[name].accepted"
                :name="name"
                :link="checkboxes[name].link"
                :text="checkboxes[name].text"
                :url="document"
                :disabled="!checkboxes[name].viewed"
                :download="checkboxes[name].download"
                :required="checkboxes[name].required"
                @urlClicked="onUrlClick"
                @onAccepted="onAccepted"
            />
            <gts-button type="button" @click="handleSend" :disabled="!meta.valid || loading" :loading="loading">Küldés</gts-button>
        </Form>
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapMutations } from 'vuex';
import { Form } from 'vee-validate';
import "@/validation";

export default {
    components: {
        Form
    },
    computed: {
        ...mapGetters({
            client: 'getClientData',
            documents: 'getClientDocuments', 
            checkboxes: 'getCheckboxes', 
            isButtonDisabled: 'isButtonDisabled',
            hash: 'getHash'
        })
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        ...mapMutations(['setAccepted', 'setViewed']),
        onUrlClick(name) {
            this.setViewed({ name, value: true });
        },
        onAccepted(data) {
            this.setAccepted(data);
        },
        async handleSend() {
            this.loading = true;
            const result = await this.$refs.form.validate();

            if(result.valid) {
                const response = await axios.post(`${process.env.VUE_APP_DASHBOARD_API_URL}/home-insurance/accept`, {
                    hash: this.hash,
                    project_id: this.client.project_id
                });
                
                this.$store.commit('setIsSend', response.data.success);
            }
            this.loading = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
</style>