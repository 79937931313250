<template>
    <gts-spinner fixed :show="loading"/>
    <Content v-if="!loading"/>
    <Form v-if="isValid && !isSend"/>
</template>

<script>
import init from "@/mixins/init";
import Form from '@/components/DataRequester/Form';
import Content from '@/components/DataRequester/Content';
import { mapGetters } from 'vuex';

export default {
    title: 'Adatbekérő',
    mixins: [init],
    components: {
        Form,
        Content
    },
    computed: {
        ...mapGetters({ isValid: 'getIsValid', isSend: 'getIsSend' })
    }
}
</script>