import axios from "axios";
import collect from "collect.js";

export default {
  fetchClientData: async ({ commit, state }) => {
      const response = await axios.post(`${process.env.VUE_APP_DASHBOARD_API_URL}/${state.type}`, {
          hash: state.hash
        });
  
        if(response.data.success) {
          commit('setClientData', response.data.data);
        }

        commit('setIsValid', response.data.success);
  },
  copyData: async ({ commit, state }, payload) => {
    const value = collect(state.clientData.inputs).firstWhere('code', payload.from).value;

    commit('addInputData', { code: payload.to, value });
  }
};