<template>
    <button class="button" type="button" :disabled="disabled">
        <slot></slot>
        <gts-spinner class="absolute" :show="loading"/>
    </button>
</template>

<script>

export default {
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
.button {
    position: relative;
}

.absolute {
    position: absolute;
    top: 0;
    right: 0;
}
</style>