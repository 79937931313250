export default () => {
    return {
        hash: null,
        type: null,
        clientData: {},
        isValid: false,
        checkboxes: {
            received: {
                link: null,
                text: 'Elfogadom, hogy a biztosítás megkötéséhez kapcsolódó tájékoztatásokat a Grantis Hungary Zrt. elektronikus úton (a jelen weblapon, valamint elektronikus levélben) nyújtsa a részemre.',
                accepted: false,
                viewed: true,
                download: false,
                required: true
            },
            info: {
                link: 'termékismertetőt',
                text: 'A fenti biztosítási termékre vonatkozóan a {link} megkaptam.',
                accepted: false,
                viewed: false,
                download: true,
                required: true
            },
            general_terms: {
                link: 'biztosítás általános szerződési feltételei',
                text: 'A fenti biztosítási termékre vonatkozóan tájékoztatást kaptam a biztosító adatairól és a {link}ről. ',
                accepted: false,
                viewed: false,
                download: true,
                required: true
            },
            contract: {
                link: 'megbízási szerződés',
                text: 'A {link}ben részletesen meghatározott tartalommal megbízom a Grantis Hungary Zrt-t az általam kiválasztott biztosítási szerződés megkötésére és kezelésére.',
                accepted: false,
                viewed: false,
                download: true,
                required: true
            },
            offer: {
                link: 'bemutatott termékek',
                text: 'A fenti biztosítási terméket igényeim felmérését követően, a részemre {link} közül, mint számomra legmegfelelőbbet választottam ki.',
                accepted: false,
                viewed: false,
                download: true,
                required: true
            },
            data: {
                link: 'Adatkezelési tájékoztató',
                text: 'A fenti alkuszi megbízáshoz kapcsolódóan megismertem a Grantis Hungary Zrt. {link}ját.',
                accepted: false,
                viewed: false,
                download: false,
                required: true
            },
            email: {
                link: null,
                text: 'Hozzájárulok, hogy a Grantis Hungary Zrt. a jövőben a szolgáltatásaira vonatkozóan reklámot küldjön nekem a megadott elérhetőségeimen (amely elküldhető postai úton, e-mailben, vagy közölhető telefonon). A jelen hozzájárulás a Grantis Hungary Zrt. Adatkezelési tájékoztatójának megfelelően bármikor visszavonható. A hozzájárulás hiánya nem akadályozza a biztosítási szerződés megkötését, továbbá hiánya vagy visszavonása semmilyen hátránnyal nem jár.',
                accepted: false,
                viewed: true,
                download: false,
                required: false
            },
        },
        isSend: false
    }
};