import collect from 'collect.js';

export default {
    getClientData: (state) => state.clientData,
    getClientDocuments: (state) => state.clientData.documents,
    getIsValid: (state) => state.isValid,
    getCheckboxes: (state) => state.checkboxes,
    isButtonDisabled: (state) => {
        const filtered = Object.values(state.checkboxes).filter(item => item.accepted && item.viewed && item.required);
        const required = Object.values(state.checkboxes).filter(item => item.required);
        return filtered.length !== required.length;
    },
    getIsSend: (state) => state.isSend,
    getHash: state => state.hash,
    getType: state => state.type,
    getGroupedInputs: state => {
        return collect(state.clientData.inputs)
        .filter(item => item.visible === 1)
        .unique('code')
        .groupBy('group')
        .all();
    }
};