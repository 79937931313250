<template>
    <h1 class="df_title">Adat bekérő űrlap - {{ client.name }} részére</h1>
    <h2>{{ modus }}</h2>
    <div class="df_main" v-if="isValid && !isSend">
        <h3>Tisztelt {{ client.name }}!</h3>
        <p>
            Köszönjük, hogy szerződésének kiválasztásában a Grantis Hungary Zrt. lehetett segítségére. <br>
            Ahhoz, hogy az ön által választott megoldás(oka)t véglegesíthessük, az alábbi adatok megadására van szükségünk. <br>
            Kérjük, töltse ki a mezőket, és kattintsan az "Adatok beküldése" gombra! 
        </p>

        <p>Üdvözlettel,<br>
        Grantis Hungary Zrt.</p>
    </div>
    <div class="df_main" v-else-if="isSend">
        <p>Köszönjük! Kollégánk nem sokára keresni fogja az előkészített dokumentumokkal!</p>

        <p>Üdvözlettel,<br>
        Grantis Hungary Zrt.</p>
    </div>
    <div class="df_main" v-else>
        <p>
            Az Ön által megnyitott hivatkozás egy már korábban lezárult folyamathoz kapcsolódik, az adatok elküldésre kerültek. <br>
            Amennyiben a megkötött szerződéssel, vagy az ügyintézéssel kapcsolatban kérdése merült fel, kérjük keresse bizalommal tanácsadóját!
        </p>

        <p>Üdvözlettel,<br>
        Grantis Hungary Zrt.</p>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({ isValid: 'getIsValid', client: 'getClientData', isSend: 'getIsSend' }),
        modus() {
            if(this.client.moduses !== undefined) {
                return this.client.moduses.join(', ')
            }

            return null;
        }
    }
}
</script>

<style lang="scss" scoped>
h3 {
    margin-top: 0;
    margin-bottom: 0;
}

h2 {
    text-align: center;
    font-size: 22px;
    margin-top: 0;
    font-weight: 500;
}

p {
    line-height: 20px;
}
</style>