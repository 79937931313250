import collect from "collect.js";

export default {
    setClientData: (state, payload) => {
        state.clientData = payload;
    },
    setIsValid: (state, payload) => {
        state.isValid = payload;
    },
    setAccepted: (state, { name, value }) => {
        state.checkboxes[name].accepted = value;
        state.checkboxes = JSON.parse(JSON.stringify(state.checkboxes));
    },
    setViewed: (state, { name, value }) => {
        state.checkboxes[name].viewed = value;
        state.checkboxes = JSON.parse(JSON.stringify(state.checkboxes));
    },
    setIsSend: (state, payload) => {
        state.isSend = payload;
    },
    setHash: (state, payload) => {
        state.hash = payload;
    },
    setType: (state, payload) => {
        state.type = payload;
    },
    addInputData: (state, payload) => {
        state.clientData.inputs = collect(state.clientData.inputs)
        .each(item => {
            if(item.code === payload.code) {
                item.value = payload.value;
            }
        }).all();
    }
}; 