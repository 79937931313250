import { Field, ErrorMessage } from 'vee-validate';
import { mapMutations } from 'vuex';
import { DateTime } from 'luxon';

export default {
	components: {
		Field,
		ErrorMessage,
	},
	props: {
		input: {
			type: Object,
			default: () => {},
		},
	},
	computed: {
		generatedId: function () {
			return `${this.input.code}_${Math.random().toString(36).substr(2, 9)}`;
		},
		validationClasses() {
			return validator => {
				return {
					ok_symbol:
						(validator.meta.valid && validator.meta.touched) ||
						(validator.meta.valid && validator.meta.dirty),
					error_symbol: (!validator.meta.valid && validator.meta.touched) || validator.errorMessage,
				};
			};
		},
		computedValue: {
			get: function () {
				if (this.input.type === 'date') {
					return this.transformDate(this.input.value);
				}

				return this.input.value;
			},
			set: function (value) {
				this.addInputData({
					code: this.input.code,
					value: this.input.type === 'date' ? this.transformDate(this.input.value ?? value) : value,
				});
			},
		},
	},
	methods: {
		...mapMutations(['addInputData']),
		transformDate(value) {
			return DateTime.fromISO(value).toISODate();
		},
	},
};
