import { mapMutations, mapActions } from "vuex";

export default {
    async mounted() {
        this.loading = true;
        this.setType(this.$route.name);
        this.setHash(this.$route.params.hash);

        await this.fetchClientData();
        this.loading = false;
    },
    methods: {
        ...mapMutations(['setType', 'setHash']),
        ...mapActions(['fetchClientData'])
    },
    data() {
        return {
            loading: false
        }
    }
}