<template>
    <Field :value="true" :name="name" :rules="required ? 'required' : ''" type="checkbox" v-slot="{ field }">
        <div class="checkbox">
            <label :for="name" class="checkbox__field">
                <div class="checkbox__wrapper">
                    <input 
                        v-bind="field" 
                        type="checkbox" 
                        :value="true" 
                        :name="name" 
                        :id="name" 
                        class="checkbox__input" 
                        :checked="value" 
                        :disabled="disabled" 
                        @change="onAccepted"
                    >
                    <span class="checkmark"></span>
                    <div class="checkbox__text"> 
                        <span v-html="computedText" ref="url">
                        </span>
                    </div>
                </div>
            </label>
        </div>
    </Field>
</template>

<script>
import { Field } from 'vee-validate';

export default {
    components: {
        Field
    },
    mounted() {
        const checkbox = this.$refs.url.children[0];
        if(checkbox) {
            checkbox.addEventListener('click', this.onUrlClick);
        }
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: null
        },
        link: {
            type: String,
            default: null
        },
        text: {
            type: String,
            default: null
        },
        url: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: true
        },
        download: {
            type: Boolean,
            default: true
        },
        required: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        computedText() {
            return this.text.replace('{link}', `<a class="checkbox__url" href="${this.url}" target="_blank" ${this.download ? 'download' : ''}>${this.link}</a>`)
        }
    },
    methods: {
        onUrlClick() {
            this.$emit('urlClicked', this.name);
        },
        onAccepted() {
            this.$emit('onAccepted', { name: this.name, value: !this.value });
        }
    }
}
</script>

<style lang="scss" scoped>
.checkbox {
    min-width: 500px;
}

.checkbox__field {
    font-size: 11px;
    line-height: 13.2px;
    width: 100%;
    line-height: 12px;
    float: right;
    color: #586274;
    padding-right: 0;
    padding-left: 0;
}

.checkbox__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;

    &:hover .checkbox__input:not(:disabled) ~ .checkmark {
        box-shadow: 0 2px 4px rgba(0, 0, 0, .5);

        &::before {
            content: "";
            display: block;
            margin: 2px auto;
            font-weight: 400;
            width: 7px;
            height: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid #ff8c00;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            opacity: .5;
            transition: .2s;
        }
    }
}

.checkbox__input {
    float: left;
    display: none;
    position: absolute;
    left: 0;
    top: 18%;
    margin: 4px 5px 0;

    &:checked ~ .checkmark::before {
        content: "";
        margin: 2px auto;
        font-weight: 400;
        width: 7px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid #ff8c00;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }

    &:disabled ~ .checkmark {
        background: #ccc;
    }
}

.checkbox__text {
    display: inline-block;
    width: calc(100% - 36px);
    font-weight: 400;
    font-family: Inter,sans-serif;
    text-align: left;
    font-size: 14px;
    line-height: 16.8px;
    color: #2e3b51;
    margin-left: 0;
}

.checkmark {
    display: block;
    color: #d5d8dc;
    background: #fff;
    transition: .2s;
    height: 24px;
    width: 24px;
    display: table;
    float: left;
    border-radius: 5px;
    border: 1px solid #d5d8dc;
    margin: 0 11px 0 0;
}
</style>