import Button from '@/components/global/Button';
import Checkbox from '@/components/global/Checkbox';
import Input from '@/components/global/Input';
import InputField from '@/components/global/InputField';
import MultiSelect from '@/components/global/MultiSelect';
import Select from '@/components/global/Select';
import Spinner from '@/components/global/Spinner';

export const registerGlobalComponents = app => {
    app.component('gts-button', Button);
    app.component('gts-checkbox', Checkbox);
    app.component('gts-input', Input);
    app.component('gts-input-field', InputField);
    app.component('gts-multiselect', MultiSelect);
    app.component('gts-select', Select);
    app.component('gts-spinner', Spinner);
}