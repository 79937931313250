<template>
    <Field :name="input.code" v-slot="validator" v-model="computedValue" rules="required">
        <div class="select-label__wrapper">
            <span class="select-label">{{ input.name }}</span>
            <div class="field-hint" v-if="input.hint">
                <span class="field-hint__icon" v-tooltip:left="input.hint">?</span>
            </div>
        </div>
        <div class="field field--select" :class="validationClasses(validator)">
            <div class="select" ref="selwrap" @click="onClick">
                <div class="option-wrap">
                    <span class="option option-label">{{ label }}</span>
                </div>
                <div class="select-chooser" v-show="show" ref="list">
                    <div
                        class="option-wrap"
                        v-for="(option, index) in input.options"
                        :key="index"
                    >
                        <span 
                            class="option" 
                            :class="{ selected: isSelected(option.value) }" 
                            @click="onChange($event, option.value)">{{ option.name }}
                        </span>
                    </div>
                </div>
            </div>
            <span class="error_message error_message--broke">{{ validator.errors[0] }}</span>
        </div>
    </Field>
</template>

<script>
import input from '@/mixins/input';
import select from '@/mixins/select';
import collect from 'collect.js';

export default {
    mixins: [input, select],
    computed: {
        computedArray: {
            get: function() { return JSON.parse(this.input.value) },
            set: function(value) {
                this.addInputData({ code: this.input.code, value: JSON.stringify(value) });
            },
        }
    },
    methods: {
        onChange(e, value) {
            e.stopPropagation();

            if(this.computedArray !== null) {
                let collection = collect(this.computedArray);

                if(this.isSelected(value)) {
                    this.computedArray = collection.reject(item => item === value).all();
                } else {
                    this.computedArray = [value, ...this.computedArray];
                }
            } else {
                this.computedArray = [value];
            }

            this.show = false;
        },
        getOptionName() {
            const options = collect(this.input.options)
            .whereIn('value', this.computedArray)
            .map(item => item.name)
            .all();

            return options.join(', ');
        }
    }
}
</script>